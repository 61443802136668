<template>
    <ContentWrapper>
        <breadcrumb v-if="order.label" icon="fad fa-file-invoice" :name="$t('wallet.view.order.invoice.label') + ' ' + order.label"/>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card card-default animated-fast fadeInUp">
                        <div class="card-body p-0">
                            <div class="row p-5">
                                <div class="col-md-6">
                                    <img src="img/logo/cannergrow-full.svg" alt="company_logo"/>
                                </div>

                                <div class="col-md-6 text-right">
                                    <p class="font-weight-bold mb-1">{{$t('wallet.view.order.invoice.label')}} {{ order.label }}</p>
                                    <p class="text-muted">{{$t('wallet.view.order.invoice.created')}} {{ $moment.utc(order.created_at).local().format('MMMM Do YYYY, h:mm:ss a') }}</p>

                                    <div class="btn-toolbar px-0 justify-content-end">
                                        <b-btn class="ml-3" href="javascript:window.print()" variant="primary" >{{$t('wallet.view.order.invoice.button.print')}}</b-btn>
                                        <b-btn class="ml-3" :to="{name: 'Wallet'}" variant="primary">{{$t('wallet.view.order.invoice.button.back')}}</b-btn>
                                        <b-btn class="ml-3" :to="{name: 'Wallet:OrderBankDetails', params: {order_label: order.label}}" variant="primary">{{$t('wallet.view.order.invoice.button.bank_details')}}</b-btn>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row px-5 pt-5 pb-0">
                                <div class="col-md-6">
                                    <p class="mb-1">Cannerald AG</p>
                                    <p class="mb-1">Kirchgasse 30</p>
                                    <p class="mb-1">3312 Fraubrunnen, BE</p>
                                    <p class="mb-1">{{$t('wallet.view.order.invoice.payment.uid')}}: CHE-402.414.053</p>
                                </div>

                                <div class="col-md-6 text-right">
                                    <p class="font-weight-bold mb-4">{{$t('wallet.view.order.invoice.payment.details')}}</p>
                                    <p class="mb-1"><span class="text-muted">{{$t('wallet.view.order.invoice.payment.type')}}</span> {{$t('wallet.component.orders.type.' + order.type)}}</p>
                                    <p class="mb-2"><span class="text-muted">{{$t('wallet.view.order.invoice.payment.status')}}</span> {{ $t('wallet.component.orders.statuses.' + order.status) }}</p>
                                </div>
                            </div>

                            <div class="row pb-5 p-5">
                                <div class="col-md-6">
                                    <p class="font-weight-bold mb-4">{{$t('wallet.view.order.invoice.client_information')}}</p>
                                    <p class="mb-1" v-if="order.address.type === 'company'">{{ order.address.company }}</p>
                                    <p class="mb-1">{{ order.address.firstname }} {{ order.address.lastname }}</p>
                                    <p class="mb-1">{{ order.address.street }} {{ order.address.house_number }}</p>
                                    <p class="mb-1">{{ order.address.zip }} {{ order.address.town }}, {{ order.address.country }}</p>
                                    <p class="mb-1" v-if="order.address.type === 'company'">{{$t('wallet.view.order.invoice.payment.uid')}}: {{order.address.ustid}}</p>
                                </div>
                            </div>

                            <div class="row p-5">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.order.invoice.table.head.id')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.order.invoice.table.head.item')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.order.invoice.table.head.quantity')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold"  v-show="order.product.type !== 'credit'">{{$t('wallet.view.order.invoice.table.head.unit_cost')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.order.invoice.table.head.total')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{{order.label}}</td>
                                                <td>{{ order.product.title }}</td>
                                                <td>{{ order.quantity }}</td>
                                                <td  v-show="order.product.type !== 'credit'">{{order.product.price}} EUR</td>
                                                <td>{{order.amount}} EUR</td>
                                            </tr>
                                            <tr v-show="order.fees > 0">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Fees</td>
                                                <td>{{order.fees | currency}} EUR</td>
                                            </tr>
                                            <tr  v-show="order.product.type !== 'credit'">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>7.7% VAT</td>
                                                <td>{{ order.amount_total - order.amount_total/1.077 | currency}} EUR</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row-reverse bg-secondary text-white p-4">
                                <div class="py-3 px-5 text-right">
                                    <div class="mb-2">Total</div>
                                    <div class="h2 font-weight-light mb-0">{{order.amount_total | currency}} EUR</div>
                                    <small  v-show="order.product.type !== 'credit'" style="font-size: 20px" class="muted">inc. 7.7% VAT</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>

    export default {
        data() {
            return {
                order: {
                    label: '',
                    status: '',
                    created_at: '',
                    quantity: 0,
                    amount: 0,
                    paid_at: 0,
                    type: '',
                    product: {
                        price: 0,
                    },
                    address: {
                        firstname: '',
                        lastname: '',
                        country: '',
                        town: '',
                        zip: '',
                        street: '',
                        house_number: '',
                    }
                },
            }
        },
        mounted() {
            this.$api.get('wallet/orders/' + this.$route.params.order_label).then(response => {
                this.order = response.data.order;
                this.address = response.data.address;
            });

            if (this.$route.params.status === 'success') {
                this.$api.post('wallet/orders/' + this.$route.params.order_label + '/mark-as-paid').then(() => {
                    this.$swal.fire('Payment marked as paid, waiting for confirmation now!', '', 'success');
                    this.$router.push({name: 'Wallet:Order', params: {order_label: this.$route.params.order_label}});
                });
            } else if (this.$route.params.status === 'failed') {
                this.$swal.fire('Payment failed!', '', 'error');
                this.$router.push({name: 'Wallet:Order', params: {order_label: this.$route.params.order_label}});
            }

        },
        methods: {}
    }
</script>
